import React from 'react';
import { Container } from 'react-bootstrap';
import Card from '../MigracaoDados/Comparative/Card';
import { Button } from '@/components';
import { categories, clinicaEsteticaActions } from '@/constants/analytics';
import { trackEvent } from '@/services/analytics';
import * as styles from './benefits.module.scss';
import { routes } from '@/constants/routes';
import { navigate } from 'gatsby';

const Benefits = () => {
  const handleTestarGratis = () => {
    navigate(routes.estetica.cadastro);
    trackEvent({
      category: categories.clinicaEstetica,
      action: clinicaEsteticaActions.benefitsTestar,
    });
  };

  return (
    <Container className={styles.container}>
      <Card />
      <div className={styles.buttonContainer}>
        <Button text="Quero testar" variant="orange" onClick={handleTestarGratis} />
      </div>
    </Container>
  );
};

export default Benefits;
