import React from 'react';

import * as styles from './feedback.module.scss';
import star from '@/assets/images/star.svg';
import morita from '@/assets/images/morita.png';

const Feedback = () => {
  return (
    <div className={styles.container}>
      <img src={morita} alt="Morita" className={styles.image} />
      <div className={styles.textContainer}>
        <p>
          No Simples Dental, a gente consegue fazer a marcação da toxina no faceograma. Se o
          paciente gostar, daqui a 6 meses quando o cliente voltar, temos gravado dentro do Simples
          Dental a quantidade e os pontos que foram aplicados. É um recurso excelente!.
        </p>
        <h4>Alexandre Morita</h4>
        <span>Um dos maiores especialistas da área de HOF no Brasil.</span>
        <div>
          {[...Array(5)].map((_, index) => (
            <img className={styles.star} src={star} key={index} />
          ))}
          <span className={styles.text}>5/5</span>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
