import React from 'react';
import { Container } from 'react-bootstrap';

import app from '@/assets/icons/app.svg';
import integracaoWhatsappWeb from '@/assets/icons/integracao-whatsapp-web.svg';
import campanhasMkt from '@/assets/icons/campanhas-de-mkt.svg';
import recuperacaoClientesInativos from '@/assets/icons/recuperacao-clientes-inativos.svg';
import financeiro from '@/assets/icons/financeiro.svg';
import alexa from '@/assets/images/alexa.png';
import controleFarmaco from '@/assets/icons/controle-de-farmaco.svg';
import funilVendas from '@/assets/icons/funil-de-vendas.svg';
import controleSeuEstoque from '@/assets/icons/controle-seu-estoque.svg';
import contratoAssinaturaEletronica from '@/assets/icons/controle-com-assinatura-eletronica.svg';
import indicadoresNegocio from '@/assets/icons/indicadores-do-negocio.svg';
import fichaAvaliacao from '@/assets/icons/ficha-de-avaliacao.svg';
import maiorSatisfacaoAtendimento from '@/assets/icons/maior-satisfacao-no-atendimento.svg';
import comissionamento from '@/assets/icons/comissionamento.svg';
import notificacoes from '@/assets/icons/notificacoes.svg';

import * as styles from './features.module.scss';

const Features = () => {
  return (
    <Container>
      <div className={styles.container}>
        <span className={styles.subtitle}>Uma ferramenta completa</span>
        <h2 className={styles.title}>Aumente suas vendas e tenha tudo em um só lugar</h2>

        <div className={styles.wrapper}>
          <div className={styles.features}>
            <img src={recuperacaoClientesInativos} alt="Recuperação de clientes inativos" />
            <p>Recuperação de clientes inativos</p>
          </div>
          <div className={styles.features}>
            <img src={alexa} alt="Agenda integrada com Alexa" />
            <p>Agenda integrada com Alexa</p>
          </div>

          <div className={styles.features}>
            <img src={fichaAvaliacao} alt="Ficha de Avaliação personalizada" />
            <p>Ficha de Avaliação personalizada</p>
          </div>
          <div className={styles.features}>
            <img src={controleFarmaco} alt="Controle de Fármaco" />
            <p>Controle de Fármaco</p>
          </div>
          <div className={styles.features}>
            <img src={contratoAssinaturaEletronica} alt="Contrato com Assinatura eletrônica" />
            <p>Contrato com Assinatura eletrônica</p>
          </div>
          <div className={styles.features}>
            <img src={comissionamento} alt="Comissionamento" />
            <p>Comissionamento</p>
          </div>
          <div className={styles.features}>
            <img src={app} alt="App para o cliente" />
            <p>App para o cliente</p>
          </div>
          <div className={styles.features}>
            <img src={app} alt="App para a clínica" />
            <p>App para a clínica</p>
          </div>
          <div className={styles.features}>
            <img src={integracaoWhatsappWeb} alt="Integrado ao WhatsApp Web" />
            <p>Integrado ao WhatsApp Web</p>
          </div>
          <div className={styles.features}>
            <img src={campanhasMkt} alt="Campanhas de MKT" />
            <p>Campanhas de MKT</p>
          </div>
          <div className={styles.features}>
            <img src={indicadoresNegocio} alt="Indicadores do negócio" />
            <p>Indicadores do negócio</p>
          </div>
          <div className={styles.features}>
            <img src={maiorSatisfacaoAtendimento} alt="Maior satisfação no atendimento" />
            <p>Maior satisfação no atendimento</p>
          </div>
          <div className={styles.features}>
            <img
              src={controleSeuEstoque}
              alt="Controle seu estoque de forma automatizada e eficiente"
            />
            <p>Controle seu estoque de forma automatizada e eficiente</p>
          </div>
          <div className={styles.features}>
            <img src={funilVendas} alt="Funil de Vendas" />
            <p>Funil de Vendas</p>
          </div>
          <div className={styles.features}>
            <img
              src={notificacoes}
              alt="Notificações de Baixa Quantidade: Se antecipe a compra de itens"
            />
            <p>Notificações de Baixa Quantidade: Se antecipe a compra de itens</p>
          </div>
          <div className={styles.features}>
            <img
              src={financeiro}
              alt="Financeiro: emissão de boletos, integração com maquininha ..."
            />
            <p>Financeiro: emissão de boletos, integração com maquininha ...</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Features;
