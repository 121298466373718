import React from 'react';
import { Container } from 'react-bootstrap';

import * as styles from './earn-more.module.scss';
import ganheMais from '@/assets/images/ganhe-mais.png';
import ganheMaisMobile from '@/assets/images/ganhe-mais-mobile.png';
import { Button } from '@/components';
import { trackEvent } from '@/services/analytics';
import { categories, clinicaEsteticaActions } from '@/constants/analytics';
import done from '@/assets/icons/done.svg';
import { routes } from '@/constants/routes';
import { navigate } from 'gatsby';

const EarnMore = () => {
  const handleTestarGratis = () => {
    navigate(routes.estetica.cadastro);
    trackEvent({
      category: categories.clinicaEstetica,
      action: clinicaEsteticaActions.earnMoreTestar,
    });
  };

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.card}>
          <img src={ganheMais} alt="Ganhe mais" className={styles.image} />
          <img src={ganheMaisMobile} alt="Ganhe mais" className={styles.imageMobile} />
          <div className={styles.content}>
            <h2>Ganhe mais</h2>
            <ul>
              <li>
                <img src={done} alt="Otimização dos protocolos" />
                Otimização dos protocolos
              </li>
              <li>
                <img src={done} alt="Fidelização dos clientes" />
                Fidelização dos clientes
              </li>
              <li>
                <img src={done} alt="Indicadores à vista" />
                Indicadores à vista
              </li>
              <li>
                <img src={done} alt="Redução de custos" />
                Redução de custos
              </li>
              <li>
                <img src={done} alt="Mais lucratividade" />
                Mais lucratividade
              </li>
              <li>
                <img src={done} alt="Inovação para seu mercado" />
                Inovação para seu mercado
              </li>
            </ul>
            <Button text="Quero testar" variant="orange" onClick={handleTestarGratis} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EarnMore;
