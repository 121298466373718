// extracted by mini-css-extract-plugin
export var button = "hero-module--button--9d05b";
export var card = "hero-module--card--75261";
export var container = "hero-module--container--25078";
export var desc = "hero-module--desc--29da0";
export var descFeature = "hero-module--desc-feature--36b0d";
export var descResponsivo = "hero-module--desc-responsivo--7a2a3";
export var feat = "hero-module--feat--e2aaa";
export var footerImage = "hero-module--footer-image--65c3d";
export var footerText = "hero-module--footer-text--9fd4a";
export var footerWrapper = "hero-module--footer-wrapper--905f1";
export var heroActions = "hero-module--hero-actions--9120f";
export var heroButtons = "hero-module--hero-buttons--44a2e";
export var imgDesktop = "hero-module--img-desktop--3d2d9";
export var imgResponsivo = "hero-module--img-responsivo--ad0e9";
export var imgWrapper = "hero-module--img-wrapper--ff104";
export var infos = "hero-module--infos--16b30";
export var innerContainer = "hero-module--inner-container--4f94a";
export var title = "hero-module--title--b13e2";
export var wrapper = "hero-module--wrapper--d8940";