import React from 'react';
import { Container } from 'react-bootstrap';
import { categories, clinicaEsteticaActions } from '@/constants/analytics';
import * as styles from './footer.module.scss';
import { links } from '@/constants/links';
import Button from '@/components/Button';
import { trackEvent } from '@/services/analytics';
import { routes } from '@/constants/routes';
import { navigate } from 'gatsby';

const Footer = () => {
  const handleClick = (link: string) => window.open(link);

  const handleFalarEspecialista = () => {
    navigate(routes.estetica.cadastroSolicitarContato);
    trackEvent({
      category: categories.clinicaEstetica,
      action: clinicaEsteticaActions.footerFalarEspecialista,
    });
  };

  const handleOpenEducateSaibaMais = () => {
    handleClick(links.estetica.saibaMais);
  };

  return (
    <>
      <div className={styles.container}>
        <Container className={styles.innerContainer}>
          <h2 className={styles.title}>
            Simplicidade com o <span>máximo de excelência.</span>
          </h2>
          <p className={styles.subtitle}>
            Sua satisfação garantida ou seu dinheiro de volta dentro de 30 dias.
            <a className={styles.link} onClick={handleOpenEducateSaibaMais}>
              Saiba mais
            </a>
          </p>

          <Button text="Falar com especialista" variant="green" onClick={handleFalarEspecialista} />

          <div className={styles.note}>
            <span className={styles.text}>*Migração sujeita a verificação de regras.</span>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
